const NotFound = () => {
    return (
        <section className="page-notfound">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>404 - Not Found</h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotFound