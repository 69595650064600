import { useTranslation } from "react-i18next";

const Studio = () => {
  const { t } = useTranslation();

  return (
    <section id="studio">
      <div className="container mt-md-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <img
              src="/img/studio-logo.jpeg"
              className="img-fluid"
              alt="The House 5tudi0"
            />
          </div>

          <div className="col-12 col-lg-6">
            <div className="bio-title mt-5 mt-md-0">
              <h3>{t("studioTitle")}</h3>
            </div>
            <div className="bio-text">
              <p>{t("studio1")}</p>
              <p>
                <iframe
                  src="https://open.spotify.com/embed/playlist/0Xely3SqADQwH8lMOLJsb6?utm_source=generator&theme=0"
                  width="100%"
                  height="380"
                  frameBorder="0"
                  allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                  title="Playlist"
                ></iframe>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Studio;
