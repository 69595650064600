import Credits from "./Credits";
import Studio from "./Studio";
import Contact from "./Contact";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="homepage">
      <section id="about">
        <div className="container mt-md-5">
          <div className="row">
            <div className="col-12 col-lg-6">
              <img
                src="/img/studio-photo.jpg"
                className="img-fluid"
                alt="Diogo Leite"
              />
            </div>

            <div className="col-12 col-lg-6">
              <div className="bio-title mt-5 mt-md-0">
                <h3>{t("aboutTitle")}</h3>
              </div>
              <div className="bio-text">
                <p>{t("about1")}</p>
                <p>{t("about2")}</p>
                <p>{t("about3")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Credits></Credits>
      <Studio></Studio>
      <Contact></Contact>
    </div>
  );
};

export default Home;
