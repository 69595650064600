import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="text-body">
            {t("contactTitle")}
            </h3>
            <a
              className="text-body"
              href="mailto:diogohleite@me.com"
              alt="Contact"
            >
              diogohleite@me.com
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
