import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useTranslation } from "react-i18next";

const Credits = () => {
  const { t } = useTranslation();

  return (
    <>
      <section id="discography">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h3>{t("creditsTitle")}</h3>
              <iframe
                src="https://open.spotify.com/embed/playlist/1ojWEQaV8jSDHVJz5f3who?utm_source=generator&theme=0"
                width="100%"
                height="380"
                frameBorder="0"
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
                title="Playlist"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section id="experiences">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
            <h3>{t("otherExperiencesTitle")}</h3>
            </div>
          </div>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 576: 1, 768: 2, 960: 3, 1140: 4 }}
          >
            <Masonry gutter="15px">
              <div className="grid-item">
                <a
                  className="card"
                  href="/img/GO’EL 2019.jpeg"
                  data-toggle="lightbox"
                  data-caption="GO’EL 2019"
                >
                  <img
                    src="/img/GO’EL 2019.jpeg"
                    className="img-fluid"
                    alt="GO’EL 2019"
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/Alesis Tour 2016.jpeg"
                  data-toggle="lightbox"
                  data-caption="Alesis Tour 2016"
                >
                  <img
                    src="/img/Alesis Tour 2016.jpeg"
                    className="img-fluid"
                    alt="Alesis Tour 2016"
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/Alesis Tour 2017.jpeg"
                  data-toggle="lightbox"
                  data-caption="Alesis Tour 2017"
                >
                  <img
                    src="/img/Alesis Tour 2017.jpeg"
                    className="img-fluid"
                    alt="Alesis Tour 2017"
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/Dia do Ritmo 2018.jpeg"
                  data-toggle="lightbox"
                  data-caption="Dia do Ritmo 2018"
                >
                  <img
                    src="/img/Dia do Ritmo 2018.jpeg"
                    className="img-fluid"
                    alt="Dia do Ritmo 2018"
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/livro-introducao-bateria-2015.jpeg"
                  data-toggle="lightbox"
                  data-caption='Livro "Introdução à bateria" 2015'
                >
                  <img
                    src="/img/livro-introducao-bateria-2015.jpeg"
                    className="img-fluid"
                    alt='Livro "Introdução à bateria" 2015'
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/Musical 2018.jpeg"
                  data-toggle="lightbox"
                  data-caption="Unhas Negras - O Espetáculo"
                >
                  <img
                    src="/img/Musical 2018.jpeg"
                    className="img-fluid"
                    alt="Unhas Negras - O Espetáculo"
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/Musical 2022.jpeg"
                  data-toggle="lightbox"
                  data-caption="Musical 2022"
                >
                  <img
                    src="/img/Musical 2022.jpeg"
                    className="img-fluid"
                    alt="Musical 2022"
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/Porto Drum Show 2019.jpeg"
                  data-toggle="lightbox"
                  data-caption="Porto Drum Show 2019"
                >
                  <img
                    src="/img/Porto Drum Show 2019.jpeg"
                    className="img-fluid"
                    alt="Porto Drum Show 2019"
                  />
                </a>
              </div>

              <div className="grid-item">
                <a
                  className="card"
                  href="/img/2009 REVISTA BATERIA TOTAL.jpeg"
                  data-toggle="lightbox"
                  data-caption="Revista Bateria Total 2019"
                >
                  <img
                    src="/img/2009 REVISTA BATERIA TOTAL.jpeg"
                    className="img-fluid"
                    alt="Revista Bateria Total 2019"
                  />
                </a>
              </div>
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </section>
    </>
  );
};

export default Credits;
