import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Credits from "./pages/Credits";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Studio from "./pages/Studio";

import "./index.css";
import "./App.css";

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    resources: {
      en: {
        translation: {
          menuCredits: 'Credits',
          menuLessons: 'Lessons',
          menuStudio: 'Studio',
          menuShop: 'Shop',
          menuContact: 'Contact',
          aboutTitle: 'About',
          about1: `Portuguese drummer. His work is present in projects such as Prana, GO‘EL, NTS, Jonny Abbey, José Cid, TWO Mirrors, Mister Pollen, Ludgero Rosas, Filipa Azevedo, Swinging Rabbits, amongst others.`,
          about2: `When not on the road, he's also a record producer and a drum teacher in his own studio.`,
          about3: `Diogo Leite is currently supported by Sonor, Sabian and Vater.`,
          creditsTitle: 'Discography',
          otherExperiencesTitle: 'Other Experiences',
          contactTitle: 'Contact',
          studioTitle: 'Studio',
          studio1: `The House 5tudi0 is a drum tracking studio built in response to changes within the music industry. Due to budget and time restrictions it’s not always possible to record drums the way you’d like, here I’m able to offer flexibility to producers/artists and the opportunity to have live drums on tracks.`,
        },
      },
      pt: {
        translation: {
          menuCredits: 'Créditos',
          menuLessons: 'Aulas',
          menuStudio: 'Estúdio',
          menuShop: 'Loja',
          menuContact: 'Contacto',
          aboutTitle: 'Sobre',
          about1: `Baterista Português. O seu trabalho está presente em projectos
          como Prana, GO'EL, NTS, Jonny Abbey, José Cid, TWO Mirrors,
          Ludgero Rosas, Filipa Azevedo, Swinging Rabbits, entre
          outros.`,
          about2: `Quando não se encontra na estrada, realiza trabalhos de produção musical, além de lecionar aulas de bateria no seu próprio estúdio.`,
          about3: `Diogo Leite actualmente é patrocinado pela Sonor, Sabian e Vater.`,
          creditsTitle: 'Discografia',
          otherExperiencesTitle: 'Outras Experiências',
          contactTitle: 'Contacto',
          studioTitle: 'Estúdio',
          studio1: `The House 5tudi0 é um estúdio de gravação de bateria, construído em resposta às mudanças na indústria da música. Devido às restrições de orçamento e tempo, nem sempre é acessível gravar bateria da forma que se pretende, aqui é possível flexibilidade aos produtores/artistas e a oportunidade de terem bateria real nos temas.`,
        },
      },
    },
    lng: "pt", // if you're using a language detector, do not define the lng option
    fallbackLng: "pt",
    supportedLngs: ["en", "pt"],
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="credits" element={<Credits />} />
          <Route path="studio" element={<Studio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback="...loading">
    <App />
  </Suspense>
);
