import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Social from "../components/Social";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";

const languages = [
  { value: "pt", text: "PT" },
  { value: "en", text: "EN" },
];

const Layout = () => {
  const { i18n, t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("pt");

  const changeLanguage = (event) => {
    setSelectedLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        bg="light"
        expand="lg"
        className="fixed-top shadow-sm"
      >
        <Container>
          <Navbar.Brand className="navbar-brand" as={Link} to="/">
            <div className="logo">Diogo Leite</div>
            <div className="subtitle">drummer.producer.educator</div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav>
              <Nav.Link eventKey="1" as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link eventKey="2" as={Link} to="/credits">
                {t("menuCredits")}
              </Nav.Link>
              <Nav.Link
                href="https://www.instagram.com/the_house_of_drummers_/"
                target="_blank"
                rel="noreferrer"
              >
                {t("menuLessons")}
              </Nav.Link>
              <Nav.Link eventKey="4" as={Link} to="/studio">
                {t("menuStudio")}
              </Nav.Link>
              <Nav.Link
                href="https://www.facebook.com/houseofDrummers/shop/"
                target="_blank"
                rel="noreferrer"
              >
                {t("menuShop")}
              </Nav.Link>
              <Nav.Link eventKey="6" as={Link} to="/contact">
                {t("menuContact")}
              </Nav.Link>

              <div className="lang-selector" onChange={changeLanguage}>
                {languages.map((lang) => {
                  return (
                    <div key={lang.value}>
                      <input
                        type="radio"
                        id={`selector-${lang.value}`}
                        value={lang.value}
                        name="language"
                      />

                      <label
                        className={`btn btn-sm ${
                          selectedLang === lang.value ? "active" : ""
                        }`}
                        htmlFor={`selector-${lang.value}`}
                      >
                        {lang.text}
                      </label>
                    </div>
                  );
                })}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet></Outlet>
      <div className="footer-wrapper">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Social></Social>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
